import React, {useState, useEffect} from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as Services from "../services"
import {navigate} from "gatsby";
import logoNovo from '../images/logo-novo.svg';

function IndexPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [process, setProcess] = useState(false);
    const [auth, setAuth] = useState(null)

    useEffect(() => {
        setAuth(localStorage.getItem('auth'));
        console.log('entra', auth);
        if (auth !== null) {
            navigate('/dashboard');
        }
    }, [])


    const loginAction = (e) => {
        e.preventDefault();
        setProcess(!process);
        Services.login(email, password).then((response) => {
            setProcess(false);
            if (response.user) {
                navigate('/dashboard');
            }
            console.log(response);
        }).catch((err) => {
            setProcess(false);
            console.log(err);
        })
    }
    return(
        <Layout>
            <div className="login">
                <div className="container mx-auto">
                    <div className="p-4 py-12">
                        <img src={logoNovo} className="w-32 mx-auto mb-6" />

                        <h1 className="font-greenup-titles font-bold text-2xl text-green-gu text-center">
                            Bienvenido de nuevo
                        </h1>
                        <h3 className="text-center text-green-light-gu">Iniciar sesión</h3>
                        <form className="mt-6 mx-auto lg:max-w-sm" method="post" onSubmit={(e) => loginAction(e)}>
                            <div className="mb-4">
                                <label className="block text-green-gu text-sm font-bold mb-2" htmlFor="username">
                                    Correo Electrónico
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    required
                                    placeholder="hola@ejemplo.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    readOnly={process}
                                />
                            </div>
                            <div className="mb-6">
                                <label className="block text-green-gu text-sm font-bold mb-2" htmlFor="password">
                                    Contraseña
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    readOnly={process}
                                />
                            </div>
                            <div className="mb-4">
                                <button type="submit" className="inline-flex items-center justify-center p-3 w-full bg-green-light-gu rounded-full font-bold text-white focus:ring-2 focus:ring-green-light-gu focus:bg-green-gu" disabled={process}>
                                    {process && (
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                    stroke-width="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>)}  {!process ? 'Ingresar ahora': 'Enviando'}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </Layout>
    )

}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
